import { useEffect, useState } from "react";

import { Box, Button, CircularProgress, Typography } from "@mui/material";
import {
  Control,
  SubmitHandler,
  UseFormClearErrors,
  UseFormHandleSubmit,
  UseFormSetValue,
  UseFormWatch,
  useFieldArray,
} from "react-hook-form";

import {
  VideoAnalysisFormState,
  VideoProcessingSteps,
} from "../../UploadVideoGroupPage";
import { DeleteButton } from "../../../../common/components/button/DeleteButton";
import MainPaperWrapper from "../../../../common/components/item/MainPaperWrapper";
import MobileStepperComponent from "./MobileStepper";
import VideoDetailsForm from "./VideoDetailsForm";
import WarningBox from "../../../../common/components/box/WarningBox";
import LeakFinderCanvasContainer from "./LeakFinderCanvasContainer";
import { PointsFormat } from "../../../../common/components/canvas/utils";
import StyledLoadingButton from "../../../../common/providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import FullPageLoader from "../../../../common/components/item/FullPageLoader";

type VideoDetailsStepProps = {
  watch: UseFormWatch<VideoAnalysisFormState>;
  onDeleteVideoHandler: () => void;
  clearErrors: UseFormClearErrors<VideoAnalysisFormState>;
  handleOnFinish: () => void;
  setVideoDetailStep: (step: number) => void;
  setStepHandler: (step: VideoProcessingSteps) => void;
  videoDetailStep: number;
  activeStep: number;
  videoIsLoading: boolean;
  setFormValue: UseFormSetValue<VideoAnalysisFormState>;
  onBackVideo: () => void;
  onNextVideo: SubmitHandler<VideoAnalysisFormState>;
  clientID: string;
  countOfVideos: number;
  control: Control<VideoAnalysisFormState>;
  handleSubmit: UseFormHandleSubmit<
    VideoAnalysisFormState,
    VideoAnalysisFormState
  >;
};

const VideoDetailsStepComponent: React.FC<VideoDetailsStepProps> = ({
  countOfVideos,
  handleSubmit,
  videoIsLoading,
  setFormValue,
  clearErrors,
  onDeleteVideoHandler,
  setStepHandler,
  activeStep,
  handleOnFinish,
  onNextVideo,
  onBackVideo,
  control,
  watch,
}) => {
  const [isChangeKeyFramePanelOpen, setIsChangeKeyFramePanelOpen] =
    useState(false);
  const [focusAreaMarker, setFocusAreaMarker] = useState<boolean | number>(
    false
  );
  const [points, setPoints] = useState<PointsFormat>([]);
  const [lastLoadingStep, setLastLoadingStep] = useState(false);

  const realIndex = activeStep - 1;

  const videos = watch("videos");

  const disableFinishButton = videos?.length !== activeStep;

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: `videos.${realIndex}.annotations.focusAreas`,
  });

  useEffect(() => {
    setLastLoadingStep(!disableFinishButton && videoIsLoading);
  }, [videoIsLoading]);

  const lastLoadingFinish = () => {
    handleOnFinish();
  };

  const onSetKeyFramePanelHandler = () =>
    setIsChangeKeyFramePanelOpen(prevState => !prevState);

  const onPrevStepHandler = () =>
    setStepHandler(VideoProcessingSteps.UPLOAD_VIDEO_STEP);

  const onAddAnnotation = (name: string) => {
    append({
      name,
      points,
    });

    setFocusAreaMarker(false);

    setPoints([]);
  };

  const focusAreaMarkerChange = (state: boolean | number) => {
    setFocusAreaMarker(state);

    if (typeof state === "number") {
      setPoints(fields[state].points);
    } else {
      setPoints([]);
    }
  };

  const onDeleteAnnotation = (index: number) => {
    remove(index);
  };

  const saveChangesHandler = (newName: string, annotationIndex: number) => {
    update(annotationIndex, { name: newName, points });

    setFocusAreaMarker(false);
  };

  const urlVideoLink = videos[realIndex].video.preview;
  const videoName = videos[realIndex].video.name;

  let filteredPoints = fields;
  let annotationPoints: PointsFormat = [];

  if (typeof focusAreaMarker === "number") {
    filteredPoints = fields.filter((field, index) => index !== focusAreaMarker);

    annotationPoints = fields[focusAreaMarker].points;
  }

  return (
    <>
      <MainPaperWrapper>
        <Box
          gap="1.5em"
          sx={{
            width: "100%",

            "@media (min-width: 1440px)": {
              display: "grid",
              alignItems: "flex-start",
              gridTemplateColumns: "1fr 1fr",
            },
          }}
        >
          <form onSubmit={handleSubmit(onNextVideo)}>
            <Box
              sx={{
                minHeight: "450px",
                marginBottom: "2em",
                "@media (min-width: 1440px)": { marginBottom: "0" },
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography variant="h5">Aditional video details</Typography>

                <Typography sx={{ fontSize: "14px" }} variant="inputLabel">
                  Please add details to each video
                </Typography>

                <MobileStepperComponent
                  countOfAllSteps={countOfVideos}
                  activeStep={activeStep}
                  nameOfEssence="videos"
                />

                <Box sx={{ marginTop: "1em" }}>
                  <VideoDetailsForm
                    focusAreaMarkerChange={focusAreaMarkerChange}
                    onAddAnnotation={onAddAnnotation}
                    onDeleteAnnotation={onDeleteAnnotation}
                    focusAreaMarker={focusAreaMarker}
                    watch={watch}
                    focusAreaFields={fields}
                    clearErrors={clearErrors}
                    setFormValue={setFormValue}
                    saveChangesHandler={saveChangesHandler}
                    control={control}
                    index={realIndex}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "1em",
                  marginTop: "1em",
                }}
              >
                <DeleteButton
                  label="Delete video"
                  onClick={onDeleteVideoHandler}
                />

                <Box sx={{ display: "flex", gap: "0.75em" }}>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={onBackVideo}
                    disabled={activeStep === 1}
                    color="inherit"
                  >
                    Back
                  </Button>

                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    disabled={activeStep === countOfVideos}
                  >
                    Next video
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>

          <Box>
            <Box
              sx={{ overflow: "visible", borderRadius: "8px", width: "100%" }}
            >
              <LeakFinderCanvasContainer
                key={"id"}
                allPoints={filteredPoints}
                saveAnnotationsData={setPoints}
                urlVideoLink={urlVideoLink}
                focusAreaMarker={focusAreaMarker}
                isChangeKeyFramePanelOpen={isChangeKeyFramePanelOpen}
                imageSource={urlVideoLink}
                points={annotationPoints}
                control={control}
                currentStep={realIndex}
              />
            </Box>

            {isChangeKeyFramePanelOpen && (
              <Box sx={{ margin: "0.4em 0 1.5em 0" }}>
                <WarningBox>
                  To change the keyframe move the dot to needed position and
                  click “save changes”
                </WarningBox>
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "0.5em",
                marginTop: isChangeKeyFramePanelOpen ? "-0.7em" : "0.5em",
              }}
            >
              <Typography variant="body2Regular">{videoName}</Typography>

              <Button
                sx={{ height: "2.5em", whiteSpace: "nowrap" }}
                variant={isChangeKeyFramePanelOpen ? "contained" : "outlined"}
                color={isChangeKeyFramePanelOpen ? "primary" : "secondary"}
                onClick={onSetKeyFramePanelHandler}
              >
                {isChangeKeyFramePanelOpen ? "Save changes" : "Change keyframe"}
              </Button>
            </Box>
          </Box>
        </Box>
      </MainPaperWrapper>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "1em",
        }}
      >
        <Box sx={{ display: "flex", gap: "0.3em", flexDirection: "column" }}>
          <Box sx={{ display: "flex", gap: "1em", justifyContent: "flex-end" }}>
            <Button
              sx={{
                marginTop: "1.5em",
              }}
              variant="outlined"
              color="inherit"
              onClick={onPrevStepHandler}
            >
              Back
            </Button>

            <StyledLoadingButton
              sx={{
                marginTop: "1.5em",
              }}
              loading={lastLoadingStep}
              type="submit"
              onClick={lastLoadingFinish}
              variant="contained"
              color="primary"
              disabled={disableFinishButton}
            >
              {lastLoadingStep && (
                <CircularProgress sx={{ marginRight: "1em" }} size={14} />
              )}
              Finish
            </StyledLoadingButton>

            {lastLoadingStep && <FullPageLoader />}
          </Box>

          <Box>
            <Typography sx={{ fontSize: "14px" }} variant="inputLabel">
              To finish the process you need to add details to all videos
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default VideoDetailsStepComponent;
