import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  UserSettingsInput,
  PaginatedUsers,
  UpdateUserMutation,
  UpdateUserMutationVariables,
} from "../../API";
import { UPDATE_USER_ROLE } from "../operations/mutations";
import {
  errorNotification,
  successNotification,
} from "../variables/notification";
import { useAuthenticatedUser } from "./useAuthenticatedUser";

export const useUpdateUserSelf = () => {
  const { customerId } = useAuthenticatedUser();

  const [updateRole, { data, loading }] = useMutation<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >(UPDATE_USER_ROLE);

  const updateUser = (
    userId: string,
    roleId: string,
    groupId?: string | null,
    userSettings?: UserSettingsInput | null
  ) => {
    return updateRole({
      variables: {
        input: {
          customerId,
          userId,
          roleId,
          groupId,
          userSettings,
        },
      },
      onCompleted: response => {
        if (response.updateUser) {
          successNotification();
        }
      },
      onError: (error: ApolloError): void => {
        errorNotification(error.message);

        console.error(error.message);
      },
      update: (cache, response): void => {
        cache.modify({
          fields: {
            getUsersByCompany(existing: PaginatedUsers) {
              const newUserRef = cache.writeFragment({
                data: response.data?.updateUser,
                fragment: gql`
                  fragment NewUser on User {
                    customer_id
                    email
                    firstName
                    id
                    lastName
                    user_group
                    user_role
                    userSettings
                  }
                `,
              });

              if (!existing) {
                return response?.data;
              }

              return {
                ...existing,
                items: existing.items.map(item => {
                  if (item?.id !== response.data?.updateUser?.id) {
                    return item;
                  }

                  return newUserRef;
                }),
              };
            },
          },
        });
      },
    });
  };

  return { updateUser, data, loading };
};
